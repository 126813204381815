import React from 'react';
import {withRouter} from 'react-router-dom';

import axios from 'axios';

import {Map, GeoJSON} from 'react-leaflet';
import geography from '../assets/map.geojson';

import logo from '../assets/logo.png';

class Overview extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            available_continents: ["Europe"],
            continent: "Europe",
            deployments: {
                "Europe": [
                    {
                        "name": "CaRi™-Research UK",
                        "link": "https://research.cloud.caristo.com/",
                        "disabled": false,
                    },

                    {
                        "name": "CaRi™-Heart UK",
                        "link": "https://uk.cloud.caristo.com/",
                        "disabled": false,
                    },
                ],
            },
        };

        axios.get(geography)
        .then((response) => {
            this.setState({geography: response.data});
        })
        .catch((error) => {
            alert(error);
        });

        this.geography_load_features = this.geography_load_features.bind(this);
    }

    geography_load_features(feature, layer) {
        layer.on("click", (e) => {
            if(this.state.available_continents.indexOf(e.target.feature.properties.CONTINENT) >= 0) {
                this.setState({continent: e.target.feature.properties.CONTINENT});
            }
        });

        if(this.state.available_continents.indexOf(feature.properties.CONTINENT) >= 0) {
            layer.options.className = "map-layer-available";
        } else {
            layer.options.className = "map-layer-unavailable";
        }
    }

    render() {
        return (
            <div>
            <div className="overview">
                <a href="https://www.caristo.com">
                    <img src={logo} className="responsive-img" alt="logo"/>
                </a>

                {this.state.geography !== undefined &&
                <Map center={[44, 7]} zoom={1}
                     zoomControl={false} doubleClickZoom={false} boxZoom={false} scrollWheelZoom={false} touchZoom={false}
                     keyboard={false} dragging={false} attributionControl={false}>

                    <GeoJSON data={this.state.geography} onEachFeature={this.geography_load_features}/>
                </Map>
                }
            </div>

            <div>
                <hr/>

                {this.state.continent !== undefined &&
                <div>
                    <h4>{ this.state.continent }</h4>
                    <br/>

                    <div className="deployments-container">
                        {this.state.deployments[this.state.continent].map((deployment) => (
                        <div key={deployment.name} className="deployment-item">
                            <h5>{deployment.name}</h5>
                            {deployment.disabled ? (
                            <b>Coming Soon</b>
                            ) : (
                            <a className="btn" href={deployment.link}>Launch</a>
                            )}
                        </div>
                        ))}
                    </div>
                </div>
                }
            </div>
            </div>
        )
    }
}

export default withRouter(Overview);
