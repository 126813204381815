import React from 'react';
import ReactDOM from 'react-dom';

import 'materialize-css/dist/css/materialize.min.css';
import 'materialize-css/dist/js/materialize.min.js';
import './materialize-extra.css'
import 'material-icons/iconfont/material-icons.css';
import 'leaflet/dist/leaflet.css';
import './index.css';

import App from './App';

ReactDOM.render(<App />, document.getElementById('root'));
