import React from 'react';
import {Route, BrowserRouter, Switch, Redirect} from 'react-router-dom';

import './App.css';
import Overview from './components/Overview.js';

class App extends React.Component {
    componentDidMount() {
        if(!window.location.host.startsWith("localhost") && window.location.host !== "cloud.caristo.com") {
            window.location.href = "https://cloud.caristo.com"+window.location.pathname;
        }
    }

    render() {
        return (
            <div className="App">
                <BrowserRouter>
                    <Switch>
                        <Route path="/" exact render={(props) => <Overview/>}/>

                        <Redirect path="*" redirect to="/"/>
                    </Switch>
                </BrowserRouter>
            </div>
        );
    }
}

export default App;
